.App {
  text-align: center;
}

 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
#animate-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(./component/images/3dview.jpg);
  background-position: 0px 0px;
  background-size: calc(100% + 400px) calc(100%);
  background-repeat: repeat-x;
  animation: animatedBackground 10s linear infinite alternate;
  max-height: 1200px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal {
  width: 100%;
  height: 70px;
  font-size: 30px;
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: #fff;
  font-family: sans-serif;
  max-width: 100vw;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}


@supports (backdrop-filter: none) {
  .modal {

    backdrop-filter: blur(10px);
    background-color: rgba(0,0, 0, 0.5);  
  }
  
}